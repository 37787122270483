import { useEffect, useState } from 'react'

export const getSessionStorageValue = <T>(storageKey: string, defaultValue?: T) => {
  try {
    const savedValue = sessionStorage.getItem(storageKey)

    if (savedValue) {
      return JSON.parse(savedValue)
    } else {
      return defaultValue
    }
  } catch {
    // invalid / corrupted values saved in session or missing session access should fall back to defaults
    return defaultValue
  }
}

export function useSessionState<T>(storageKey: string, defaultValue?: T, forcedValue?: T) {
  const initialValue = getSessionStorageValue(storageKey, defaultValue) as T

  const originalHook = useState<T>(forcedValue ?? initialValue)

  const value = originalHook[0]

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [storageKey, value])

  return originalHook
}
