import { Container, Stack, Typography as MuiTypography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled(Container)`
  min-height: 70px;
  background-color: ${(props) => props.theme.palette.grey[100]};
`

const Typography = styled(MuiTypography)`
  padding: ${(props) => props.theme.spacing(0.5, 2)};
`

export const Footer = () => {
  return (
    <FooterContainer disableGutters maxWidth={false}>
      <Stack
        py={2}
        px={{ xs: 2, sm: 4, md: 8 }}
        sx={{ height: '100%' }}
        flexWrap='wrap'
        alignItems='center'
        direction='row'
      >
        <Typography color='primary'>www.hrx.eu</Typography>
      </Stack>
    </FooterContainer>
  )
}
