import createSvgIcon from '@mui/material/utils/createSvgIcon'
import React from 'react'

/* eslint-disable max-len */
export default createSvgIcon(
  <svg>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM2 1H22C22.5523 1 23 1.44772 23 2V22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22V2C1 1.44772 1.44772 1 2 1Z'
    />
    <path
      className='HrxSvgIconCheck'
      d='M18 8.74055L15.7895 6.5L10.8 12.3419L8.21053 9.30412L6 11.5447L10.8 17.1667L18 8.74055Z'
    />
  </svg>,
  'CheckedCheckbox'
)
/* eslint-enable max-len */
