import { useSnackbar, SnackbarProvider, OptionsObject } from 'notistack'
import React, { useMemo } from 'react'

import { useI18n } from './useI18n'

export const NotificationsProvider: React.FC = ({ children }) => {
  return <SnackbarProvider>{children}</SnackbarProvider>
}

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const { t } = useI18n()

  return useMemo(() => {
    return {
      notifySuccess: (message?: string, options: OptionsObject = {}) =>
        enqueueSnackbar(message ?? t('notifications.save_success'), { variant: 'success', ...options }),
      notifyError: (message?: string, options: OptionsObject = {}) =>
        enqueueSnackbar(message ?? t('notifications.save_error'), { variant: 'error', ...options })
    }
  }, [enqueueSnackbar, t])
}
