import { useCallback } from 'react'
import { FieldError } from 'react-hook-form'

import { useI18n } from './useI18n'

interface CompositeFieldErrorMessage {
  translationKey: string
  interpolationValues: Record<string, string>
}

type FieldErrorMessage = undefined | string | CompositeFieldErrorMessage

export const useFieldErrorHelper = () => {
  const { t } = useI18n()

  const helper = useCallback(
    (error: FieldError | undefined) => {
      if (!error) {
        return undefined
      }

      const displayableMessage = error.message as FieldErrorMessage

      if (typeof displayableMessage === 'object') {
        const { translationKey, interpolationValues } = displayableMessage
        return t(translationKey, { ...interpolationValues, defaultValue: translationKey })
      } else if (displayableMessage) {
        return t(displayableMessage, { defaultValue: displayableMessage })
      } else {
        return undefined
      }
    },
    [t]
  )

  return helper
}
