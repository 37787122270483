import { AppBar as MuiAppBar, Toolbar as MuiToolbar, Grid, Divider, Stack, Box } from '@mui/material'
import { MenuIcon } from 'icons'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useCurrentUser } from 'hooks'

import logo from 'assets/logo.svg'

import { IconButton } from 'components/buttons'

import { AuthMenu } from './authMenu'
import { LanguageMenu } from './languageMenu'
import { MobileNavigation } from './mobileNavigation'
import { NavigationItems } from './navigationItems'
import { OrderTrackingMenu } from './orderTrackingMenu'
import { ProfileMenu } from './profileMenu'

const AppBar = styled(MuiAppBar)`
  background-color: ${(props) => props.theme.palette.background.paper};
  min-height: 76px;
`

const Toolbar = styled(MuiToolbar)`
  min-height: 76px;
  padding: ${(props) => props.theme.spacing(0, 3, 0, 1)};
`

export const Navbar: React.FC = () => {
  const { authorized } = useCurrentUser()
  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = (state: boolean) => {
    setOpenDrawer(state)
  }

  return (
    <AppBar position='static'>
      <Toolbar variant='dense'>
        <Link to='/'>
          <img src={logo} height={60} />
        </Link>
        <Grid pl={3} py={1} container spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Grid item xs alignItems='center' display='flex'>
            <NavigationItems />
          </Grid>
          <Grid item display='flex' alignItems='center'>
            <Stack
              direction='row'
              divider={<Divider sx={{ height: 28 }} orientation='vertical' />}
              spacing={3}
              sx={{ alignItems: 'center' }}
            >
              <OrderTrackingMenu />
              <LanguageMenu />
              {authorized && <ProfileMenu />}
              {authorized && <AuthMenu />}
            </Stack>
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end' sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Stack
            direction='row'
            divider={<Divider sx={{ height: 28 }} orientation='vertical' />}
            spacing={3}
            sx={{ alignItems: 'center', display: { xs: 'flex', md: 'none' } }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <OrderTrackingMenu />
            </Box>
            <IconButton variant='text' onClick={() => toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Toolbar>
      <MobileNavigation open={openDrawer} onToggle={toggleDrawer} />
    </AppBar>
  )
}
