import { DesktopTimePicker } from '@mui/lab'
import { TextField, FormControlLabelProps } from '@mui/material'
import React, { forwardRef } from 'react'
import { useController } from 'react-hook-form'
import { formatBaseTime } from 'utils'

import { useFieldErrorHelper } from 'hooks'

import { UniversalControllerProps } from './common'

export const TimePickerInput = forwardRef<
  HTMLInputElement,
  UniversalControllerProps & Pick<FormControlLabelProps, 'label'>
>((props, ref) => {
  const { name, control, rules, defaultValue, label } = props

  const fieldErrorHelper = useFieldErrorHelper()

  const {
    field,
    fieldState: { invalid, error }
  } = useController({ name, control, rules, defaultValue })

  const { onBlur, ...fieldBase } = field

  return (
    <DesktopTimePicker
      ampm={false}
      {...fieldBase}
      ref={ref}
      label={label}
      onChange={(value) => field.onChange(formatBaseTime(value))}
      value={field.value || null}
      renderInput={(params) => (
        <TextField
          variant='standard'
          helperText={fieldErrorHelper(error)}
          fullWidth
          name={name}
          {...params}
          error={invalid || params.error}
          InputProps={{ ...params.InputProps, onBlur }}
        />
      )}
    />
  )
})
TimePickerInput.displayName = 'TimePickerInput'
