import { TextField, TextFieldProps, Autocomplete, AutocompleteProps } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'

import { useFieldErrorHelper } from 'hooks'

import { UniversalControllerProps } from './common'

export const SelectInput = <T,>({
  name,
  control,
  rules,
  defaultValue,
  label,
  ...autocompleteProps
}: UniversalControllerProps &
  Omit<AutocompleteProps<T, boolean, boolean, undefined>, 'renderInput' | 'autoHighlight' | 'autoSelect'> &
  Pick<TextFieldProps, 'label'>) => {
  const fieldErrorHelper = useFieldErrorHelper()

  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error }
  } = useController({ name, control, rules, defaultValue })

  return (
    <Autocomplete
      {...autocompleteProps}
      value={value}
      onChange={(_e, value) => onChange(value)}
      autoHighlight
      autoSelect
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant='standard'
          error={invalid}
          helperText={fieldErrorHelper(error)}
          label={label}
          InputProps={{ ...params.InputProps, onBlur }}
        />
      )}
    />
  )
}
