import {
  Stack,
  Typography as MuiTypography,
  List,
  ListItem as MuiListItem,
  Collapse,
  MenuItem,
  Button
} from '@mui/material'
import { RoleScopeEnum } from 'graphqlSchema'
import { GearIcon, BoxIcon } from 'icons'
import { compact, map, startsWith } from 'lodash'
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Can } from 'utils'

import { useI18n, useCurrentRole, useAbility } from 'hooks'

import { Dropdown, CloseDropdownContext } from 'components/dropdowns'
import { Link as MuiLink } from 'components/link'

interface DropdownItem {
  to: string
  label: string
}

const Link = styled(MuiLink)`
  width: 100%;
`

const StyledButton = styled(Button)`
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`

const SubList = styled(List)`
  padding-top: ${(props) => props.theme.spacing(1)};
`

const SubNavigationText = styled(MuiTypography)`
  font-size: 0.88rem;
`

const BaseListItem = styled(MuiListItem)`
  &.Mui-selected,
  &:hover {
    p.MuiTypography-root {
      opacity: 70%;
    }
  }
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`

const SubListItem = styled(BaseListItem)`
  padding: ${(props) => props.theme.spacing(0, 7)};
  margin-top: ${(props) => props.theme.spacing(1.5)};
  color: ${(props) => props.theme.palette.text.secondary};
  &.Mui-selected {
    background: none;
    color: ${(props) => props.theme.palette.primary.main};
    .MuiLink-root {
      background-color: ${(props) => props.theme.palette.grey[100]};
    }
  }
`

const ListItem = styled(BaseListItem)`
  padding: 0;
  border-left: 4px solid transparent;
  margin-top: ${(props) => props.theme.spacing(3.5)};
  color: ${(props) => props.theme.palette.text.secondary};
  cursor: pointer;
  &.Mui-selected:hover {
    background: none;
  }
  &.Mui-selected {
    background: none;
    color: ${(props) => props.theme.palette.primary.main};
    border-left: 4px solid ${(props) => props.theme.palette.primary.main};
  }
`

const ListItemContent = styled.div`
  padding: ${(props) => props.theme.spacing(0.25, 3)};
  width: 100%;
  display: flex;
`

const BaseLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
`

const SubNavLink = styled(BaseLink)`
  padding: ${(props) => props.theme.spacing(1, 1.5)};
  color: inherit;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`

const NavLink = styled(BaseLink)`
  padding: ${(props) => props.theme.spacing(0.25, 3)};
  color: inherit;
`

const NavText = styled(MuiTypography)`
  display: inline-block;
  font-size: 1rem;
  padding-left: ${(props) => props.theme.spacing(1.5)};
`

const DropdownItem: React.FC<{ selected: boolean }> = ({ children, selected }) => {
  const handleClose = useContext(CloseDropdownContext)

  const onClick = () => {
    handleClose()
  }

  return (
    <MenuItem onClick={onClick} selected={selected} disableGutters>
      {children}
    </MenuItem>
  )
}

const HorizontalNavigation: React.FC<{ adminNavLinks: DropdownItem[]; settingNavLinks: DropdownItem[] }> = ({
  adminNavLinks,
  settingNavLinks
}) => {
  const { t } = useI18n()
  const { pathname: path } = useLocation()

  return (
    <Stack sx={{ color: 'text.primary' }} spacing={1} direction='row'>
      <Can I='read' a='Order'>
        <StyledButton size='large' color={startsWith(path, '/orders') ? 'primary' : 'inherit'}>
          <Link color='inherit' to='/orders'>
            {t('navbar.orders')}
          </Link>
        </StyledButton>
      </Can>

      {adminNavLinks.length > 0 && (
        <Dropdown
          label={t('navbar.global')}
          size='large'
          data-action='navigate-page-global'
          horizontal='center'
          color={startsWith(path, '/global') ? 'primary' : 'inherit'}
        >
          {map(adminNavLinks, ({ to, label }) => (
            <DropdownItem key={to} selected={startsWith(path, to)}>
              <Link color='inherit' to={to}>
                {t(label)}
              </Link>
            </DropdownItem>
          ))}
        </Dropdown>
      )}

      {settingNavLinks.length > 0 && (
        <Dropdown
          label={t('navbar.settings')}
          size='large'
          data-action='navigate-page-settings'
          horizontal='center'
          color={startsWith(path, '/settings') ? 'primary' : 'inherit'}
        >
          {map(settingNavLinks, ({ to, label }) => (
            <DropdownItem key={to} selected={startsWith(path, to)}>
              <Link color='inherit' to={to}>
                {t(label)}
              </Link>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Stack>
  )
}

const VerticalNavigation: React.FC<{ adminNavLinks: DropdownItem[]; settingNavLinks: DropdownItem[] }> = ({
  adminNavLinks,
  settingNavLinks
}) => {
  const { t } = useI18n()
  const { pathname: path } = useLocation()
  const [expanded, setExpanded] = useState<string | false>('')

  useEffect(() => {
    setExpanded(path.split('/')[1])
  }, [path])

  return (
    <>
      <List disablePadding>
        <Can I='read' a='Order'>
          <ListItem selected={startsWith(path, '/orders')}>
            <NavLink to='/orders'>
              <BoxIcon />
              <NavText>{t('navbar.orders')}</NavText>
            </NavLink>
          </ListItem>
        </Can>

        {adminNavLinks.length > 0 && (
          <>
            <ListItem selected={startsWith(path, '/global')} onClick={() => setExpanded('global')}>
              <ListItemContent>
                <GearIcon />
                <NavText>{t('navbar.global')}</NavText>
              </ListItemContent>
            </ListItem>
            <Collapse in={expanded === 'global'}>
              <SubList>
                {map(adminNavLinks, ({ to, label }) => (
                  <SubListItem selected={startsWith(path, to)} key={to}>
                    <SubNavLink to={to}>
                      <SubNavigationText>{t(label)}</SubNavigationText>
                    </SubNavLink>
                  </SubListItem>
                ))}
              </SubList>
            </Collapse>
          </>
        )}

        {settingNavLinks.length > 0 && (
          <>
            <ListItem selected={startsWith(path, '/settings')} onClick={() => setExpanded('settings')}>
              <ListItemContent>
                <GearIcon />
                <NavText>{t('navbar.settings')}</NavText>
              </ListItemContent>
            </ListItem>
            <Collapse in={expanded === 'settings'}>
              <SubList>
                {map(settingNavLinks, ({ to, label }) => (
                  <SubListItem selected={startsWith(path, to)} key={to}>
                    <SubNavLink to={to}>
                      <SubNavigationText>{t(label)}</SubNavigationText>
                    </SubNavLink>
                  </SubListItem>
                ))}
              </SubList>
            </Collapse>
          </>
        )}
      </List>
    </>
  )
}

export const NavigationItems: React.FC<{ vertical?: boolean }> = ({ vertical = false }) => {
  const { scope, customerId } = useCurrentRole()
  const ability = useAbility()

  const adminNavLinks: DropdownItem[] = useMemo(() => {
    if (scope !== RoleScopeEnum.Global) {
      return []
    }

    return compact([
      ability.can('update', 'Customer') && {
        to: '/global/customers',
        label: 'global.tabs.customers'
      },
      ability.can('update', { __typename: 'Role', scope: RoleScopeEnum.Global }) && {
        to: '/global/users',
        label: 'global.tabs.users'
      },
      ability.can('update', 'PartnerConfiguration') && { to: '/global/partners', label: 'global.tabs.partners' },
      ability.can('read', 'CustomerAPIClientFile') && {
        to: '/global/api_client_files',
        label: 'global.tabs.api_client_files'
      },
      ability.can('update', 'DefaultDeliveryCountryPriceRule') && { to: '/global/prices', label: 'global.tabs.prices' },
      ability.can('read', 'CustomerAPIToken') && {
        to: '/global/api_tokens',
        label: 'global.tabs.api_tokens'
      }
    ])
  }, [scope, ability])

  const settingNavLinks: DropdownItem[] = useMemo(() => {
    if (scope !== RoleScopeEnum.Customer) {
      return []
    }

    return compact([
      ability.can('update', 'PickupLocation') && {
        to: '/settings/pickup_locations',
        label: 'settings.tabs.pickup_locations'
      },
      ability.can('update', 'PickupSchedule') && {
        to: '/settings/pickup_schedules',
        label: 'settings.tabs.pickup_schedules'
      },
      ability.can('update', { __typename: 'Role', scope: RoleScopeEnum.Customer, customerId }) && {
        to: '/settings/users',
        label: 'settings.tabs.users'
      },
      { to: '/settings/contacts', label: 'settings.tabs.contacts' },
      { to: '/settings/pricing', label: 'settings.tabs.pricing' },
      ability.can('read', 'CustomerAPIClientFile') && {
        to: '/settings/api_clients',
        label: 'settings.tabs.api_clients'
      }
    ])
  }, [scope, ability, customerId])

  return (
    <div>
      {vertical ? (
        <VerticalNavigation adminNavLinks={adminNavLinks} settingNavLinks={settingNavLinks} />
      ) : (
        <HorizontalNavigation adminNavLinks={adminNavLinks} settingNavLinks={settingNavLinks} />
      )}
    </div>
  )
}
