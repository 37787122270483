import { Grid, TextField, TextFieldProps, Typography } from '@mui/material'
import { LocaleEnum } from 'graphqlSchema'
import { map } from 'lodash'
import React, { useState } from 'react'
import { useController } from 'react-hook-form'

import { useFieldErrorHelper, useI18n } from 'hooks'

import { UniversalControllerProps } from './common'

export const TextFieldInput = ({
  name,
  control,
  rules,
  defaultValue,
  fullWidth = true,
  ...textFieldProps
}: UniversalControllerProps & TextFieldProps) => {
  const fieldErrorHelper = useFieldErrorHelper()

  const {
    field,
    fieldState: { invalid, error }
  } = useController({ name, control, rules, defaultValue })

  const { onBlur, ...fieldBase } = field

  return (
    <TextField
      {...fieldBase}
      variant='standard'
      helperText={fieldErrorHelper(error)}
      error={invalid}
      fullWidth={fullWidth}
      {...textFieldProps}
      InputProps={{ ...textFieldProps.InputProps, onBlur }}
    />
  )
}

export const TranslatedTextFieldInput = ({ label, name, ...other }: UniversalControllerProps & TextFieldProps) => {
  const { currentLocale } = useI18n()
  const [locale, setLocale] = useState(currentLocale)

  const localeOptions = map(LocaleEnum, (localeOption) => {
    const current = localeOption === locale

    return (
      <Grid item xs={1} key={localeOption} onClick={() => setLocale(localeOption)} sx={{ cursor: 'pointer' }}>
        <Typography
          color={current ? 'primary' : 'secondary'}
          sx={{ textDecoration: current ? 'underline' : undefined, textAlign: 'center' }}
        >
          {localeOption.toUpperCase()}
        </Typography>
      </Grid>
    )
  })

  return (
    <Grid container>
      <Grid item xs>
        <Typography>{label}</Typography>
      </Grid>
      {localeOptions}
      <Grid item xs={12}>
        <TextFieldInput {...other} name={`${name}.${locale}`} key={locale} />
      </Grid>
    </Grid>
  )
}
