import { Grid, Container, Stack, Box, AppBar as MuiAppBar, Toolbar as MuiToolbar } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

import { useI18n } from 'hooks'

import LoginBackground from 'assets/login_background.png'
import logo from 'assets/logo_white.svg'

import { Footer } from 'components/footer'
import { LanguageMenu } from 'components/navbar/languageMenu'

import { CalloutTypography } from './common'

const StyledLanguageMenu = styled(LanguageMenu)`
  color: white;
`

const AppBar = styled(MuiAppBar)`
  background-color: ${(props) => props.theme.palette.primary.main};
  height: 76px;
`

const Toolbar = styled(MuiToolbar)`
  height: 100%;
  padding: ${(props) => props.theme.spacing(0, 1)};
  justify-content: space-between;
`

const FormWrapper = styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => (props.theme.shape.borderRadius as number) * 2.5}px;
`

const LoginContainer = styled(Container)`
  background-image: url(${LoginBackground});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
`

const LoginHeader = () => {
  return (
    <AppBar position='static'>
      <Toolbar>
        <img src={logo} height={60} />
        <StyledLanguageMenu />
      </Toolbar>
    </AppBar>
  )
}

export const Layout: React.FC = ({ children }) => {
  const { t } = useI18n()

  return (
    <Stack sx={{ height: '100vh' }}>
      <LoginHeader />
      <LoginContainer sx={{ flexGrow: 1 }} disableGutters maxWidth={false}>
        <Grid py={{ xs: 2, sm: 10 }} sx={{ height: '100%' }} container justifyContent='center' alignItems='center'>
          <Grid px={4} container item justifyContent='center'>
            <Grid item px={{ xs: 0, sm: 8 }} pb={3} pt={{ xs: 0, sm: 5 }}>
              <CalloutTypography variant='h3' sx={{ fontWeight: 800 }}>
                {t('login.welcome')}
              </CalloutTypography>
              <CalloutTypography variant='h6' sx={{ fontWeight: 600 }}>
                {t('login.welcome_sub')}
              </CalloutTypography>
            </Grid>
            <Grid item>
              <FormWrapper sx={{ width: 385 }}>
                <Grid sx={{ px: 4, pt: 7, pb: 8 }}>{children}</Grid>
              </FormWrapper>
            </Grid>
          </Grid>
        </Grid>
      </LoginContainer>
      <Footer />
    </Stack>
  )
}
