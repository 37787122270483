import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButtonTypeMap as MuiIconButtonTypeMap
} from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const StyledIconButton = styled(MuiButton)`
  border-color: ${(props) => props.theme.palette.grey[200]};
  min-width: auto;
  padding: ${(props) => props.theme.spacing(1)};
  &.MuiButton-root {
    color: ${(props) => props.theme.palette.grey[700]};
    border-radius: ${(props) => (props.theme.shape.borderRadius as number) * 1.5}px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-color: ${(props) => props.theme.palette.grey[200]};
  }
`

export const IconButton = <D extends React.ElementType = MuiIconButtonTypeMap['defaultComponent'], P = unknown>(
  props: MuiButtonProps<D, P>
) => {
  return (
    <StyledIconButton variant='outlined' {...props}>
      {props.children}
    </StyledIconButton>
  )
}
