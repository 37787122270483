import { IconButton, Menu, PopoverOrigin, IconButtonProps } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { CloseDropdownContext } from './common'

type IconDropdownProps = {
  icon: React.ReactNode
  vertical?: PopoverOrigin['vertical']
  horizontal?: PopoverOrigin['horizontal']
  withBackdrop?: boolean
} & IconButtonProps

export const IconDropdown: React.FC<IconDropdownProps> = ({
  icon,
  children,
  withBackdrop = false,
  vertical = 'bottom',
  horizontal = 'right',
  ...iconButtonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const origin = { vertical, horizontal }

  return (
    <CloseDropdownContext.Provider value={handleClose}>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} {...iconButtonProps}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={origin}
        transformOrigin={{ vertical: 'top', horizontal: horizontal }}
        open={!!anchorEl}
        onClose={handleClose}
        BackdropProps={{ invisible: !withBackdrop }}
      >
        {children}
      </Menu>
    </CloseDropdownContext.Provider>
  )
}
