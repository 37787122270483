import { Box, Typography } from '@mui/material'
import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { getCsrfHeaders } from 'utils'

import { useI18n, useNotifications } from 'hooks'

import { Link } from 'components/link'
import { CalloutTypography, LoginInput, LoginButton } from 'components/unauthorized/common'
import { Layout } from 'components/unauthorized/layout'

interface Inputs {
  email: string
}

export const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { isSubmitting }
  } = useForm<Inputs>({ defaultValues: { email: '' } })
  const { t } = useI18n()

  const { notifyError, notifySuccess } = useNotifications()

  const onSubmit: SubmitHandler<Inputs> = async ({ email }) => {
    const { ok } = await fetch(' /users/password', {
      method: 'POST',
      body: JSON.stringify({ user: { email } }),
      headers: {
        ...getCsrfHeaders(),
        'Content-Type': 'application/json'
      }
    })

    if (ok) {
      notifySuccess(t('login.forgot_password_confirm'))
      reset()
    } else {
      notifyError(t('login.forgot_password_error'))
      setError('email', { type: 'invalid' })
    }
  }

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CalloutTypography variant='h6' sx={{ fontWeight: 700 }}>
          {t('login.forgot_password')}
        </CalloutTypography>
        <Typography sx={{ color: 'white', fontWeight: '500' }}>{t('login.forgot_password_subtext')}</Typography>
        <LoginInput
          name='email'
          control={control}
          rules={{ required: true }}
          defaultValue=''
          variant='filled'
          label={t('login.email')}
          fullWidth
          sx={{ mt: 2 }}
        />
        <Box
          sx={{ mt: 2, pr: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white' }}
        >
          <LoginButton variant='outlined' color='primary' type='submit' disabled={isSubmitting}>
            {t('login.forgot_password_button')}
          </LoginButton>
          <Link to='/'>{t('buttons.back')}</Link>
        </Box>
      </form>
    </Layout>
  )
}
