import { bootstrap } from 'bootstrap'
import 'errorReporting'
import React from 'react'
import { render } from 'react-dom'

import { App } from 'pages/app'

bootstrap()

const rootElement = document.getElementById('root')

render(<App />, rootElement)
