import { AutocompleteInputChangeReason } from '@mui/material'
import { CustomerSortEnum, SortDirectionEnum } from 'graphqlSchema'
import { useCustomersListLazyQuery } from 'graphqlSchema'
import { throttle } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

const queryOptions = (query: string) => ({
  variables: {
    q: {
      sorts: [{ name: CustomerSortEnum.Name, dir: SortDirectionEnum.Desc }],
      nameMatches: query.length > 0 ? `%${query}%` : undefined
    },
    first: 25
  }
})

const inputThrottleMs = 500

export const useCustomerOptions = (initialInput?: string) => {
  const [inputValue, setInputValue] = useState(initialInput || '')
  const [queryChanged, setQueryChanged] = useState(false)

  const [runQuery, { data, loading }] = useCustomersListLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setQueryChanged(false)
  })

  const fetchOptions = useMemo(
    () =>
      throttle(
        (query: string) => {
          runQuery(queryOptions(query))
        },
        inputThrottleMs,
        { leading: false, trailing: true }
      ),
    [runQuery]
  )

  const onInputChange = useCallback(
    (_e, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input') {
        setQueryChanged(true)
        setInputValue(value)
        fetchOptions(value)
      } else {
        setInputValue(initialInput || '')
      }
    },
    [fetchOptions, initialInput]
  )

  const onOpen = useCallback(() => runQuery(queryOptions('')), [runQuery])

  const apparentlyLoading = queryChanged || loading

  const options = apparentlyLoading ? [] : data?.customers.nodes || []

  return { inputValue, onInputChange, onOpen, options, setInputValue, loading: apparentlyLoading }
}
