import { List, ListItem, ListItemButton, ListItemText, Container, Paper, ListItemIcon } from '@mui/material'
import { RoleScopeEnum, ServiceCountryEnum, CurrentUserQuery } from 'graphqlSchema'
import { AccountIcon, CloseIcon } from 'icons'
import { find, map, sortBy } from 'lodash'
import React, { useEffect, useState, createContext, useContext, useReducer, Reducer } from 'react'

import logo from 'assets/logo.svg'

import { AuthorizedState, useCurrentUser } from './useCurrentUser'
import { useSessionState } from './useSessionState'

type BackendRole = CurrentUserQuery['currentUser']['roles']['nodes'][number]

type CurrentRole = {
  id: string
  customerId: string
  customerName?: string
  customerCountry?: ServiceCountryEnum
  scope: RoleScopeEnum
  primaryCountry?: ServiceCountryEnum | null
}

const blankRole: CurrentRole = { id: '', customerId: '', customerName: '', scope: RoleScopeEnum.Customer }

export const CURRENT_SESSION_ROLE_STORAGE_ID = 'session-role-id'

const CurrentRoleContext = createContext<CurrentRole>(blankRole)

const transformRole = (state: CurrentRole, nextRole?: BackendRole) => {
  if (nextRole && nextRole.active) {
    const { id, customerId, customer, scope, primaryCountry } = nextRole
    return {
      id,
      customerId: customerId as string,
      scope,
      customerName: customer?.name,
      customerCountry: customer?.country,
      primaryCountry
    }
  } else {
    return state
  }
}

const RoleSelector: React.FC<{ allRoles: BackendRole[]; setCurrentRole: (role: BackendRole) => void }> = ({
  allRoles,
  setCurrentRole
}) => {
  const options = map(
    sortBy(allRoles, ({ active }) => (active ? 0 : 1)),
    (role) => {
      const { id, customer, active } = role
      return (
        <ListItem key={id} data-testid={id}>
          <ListItemButton onClick={() => setCurrentRole(role)}>
            <ListItemIcon>{active ? <AccountIcon /> : <CloseIcon />}</ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: active ? 'secondary' : 'primary.text' }}>
              {customer?.name ?? 'HRX'} {active ? null : '(deactivated)'}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      )
    }
  )

  return (
    <Container maxWidth='sm'>
      <Paper sx={{ mt: 5, pb: 2 }}>
        <img src={logo} />
        <List>{options}</List>
      </Paper>
    </Container>
  )
}

export const CurrentRoleProvider: React.FC = ({ children }) => {
  const [currentRole, setCurrentRole] = useReducer<Reducer<CurrentRole, BackendRole>>(transformRole, blankRole)
  const [allRoles, setAllRoles] = useState<BackendRole[]>([])
  const [sessionRoleId, setSessionRoleId] = useSessionState<CurrentRole['id'] | null>(CURRENT_SESSION_ROLE_STORAGE_ID)

  const { roles, authorizedState } = useCurrentUser()

  useEffect(() => {
    const newRoles = roles?.nodes || []

    setAllRoles(newRoles)

    if (newRoles.length === 1) {
      setCurrentRole(newRoles[0])
    } else if (sessionRoleId && newRoles.length > 0) {
      const sessionRole = find(newRoles, { id: sessionRoleId })

      if (sessionRole) {
        setCurrentRole(sessionRole)
      }
    }
  }, [roles, sessionRoleId])

  useEffect(() => {
    if (currentRole.id) {
      sessionStorage.clear()
      setSessionRoleId(currentRole.id)
    }
  }, [currentRole.id, setSessionRoleId])

  useEffect(() => {
    if (authorizedState === AuthorizedState.no) {
      setSessionRoleId(null)
    }
  }, [authorizedState, setSessionRoleId])

  const showRoleSelector = !currentRole.id && allRoles.length > 0

  return (
    <CurrentRoleContext.Provider value={currentRole}>
      {showRoleSelector ? <RoleSelector allRoles={allRoles} setCurrentRole={setCurrentRole} /> : children}
    </CurrentRoleContext.Provider>
  )
}

export const useCurrentRole = () => {
  return useContext(CurrentRoleContext)
}
