import * as Yup from 'yup'

export const setupValidationLocales = () => {
  Yup.setLocale({
    mixed: {
      required: 'errors.common.required',
      notType: 'errors.common.required'
    },
    string: {
      min: ({ min }) => ({ translationKey: 'errors.common.string_min', interpolationValues: { min } }),
      max: ({ max }) => ({ translationKey: 'errors.common.string_max', interpolationValues: { max } }),
      email: 'errors.common.email_format'
    },
    number: {
      min: ({ min }) => ({ translationKey: 'errors.common.number_gteq', interpolationValues: { min } }),
      max: ({ max }) => ({ translationKey: 'errors.common.number_lteq', interpolationValues: { max } }),
      moreThan: ({ more }) => ({ translationKey: 'errors.common.number_gt', interpolationValues: { min: more } })
    }
  })
}

setupValidationLocales() // Ensures that messages are defined before we define common validators below

export const validatePassword = Yup.string().test({
  name: 'blank-or-minimum',
  test: function (value?: string) {
    const length = value ? value.length : 0
    const min = 10

    const isValid = length === 0 || length >= min

    return isValid
      ? true
      : this.createError({
          message: { translationKey: 'errors.common.string_min', interpolationValues: { min } }
        })
  }
})

export const validateObjectWithId = Yup.object()
  .shape({
    id: Yup.string().required()
  })
  .required()
