import { ApolloProvider } from '@apollo/client'
import loadable from '@loadable/component'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Container } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ErrorBoundary } from 'errorReporting'
import React, { useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { theme } from 'theme'

import { CurrentRoleProvider } from 'hooks/useCurrentRole'
import { CurrentUserProvider, useCurrentUser, AuthorizedState, useAbility } from 'hooks/useCurrentUser'
import { I18nProvider } from 'hooks/useI18n'
import { NotificationsProvider } from 'hooks/useNotifications'

import { LoadingContent } from 'components/loadingContent'
import { Navbar } from 'components/navbar'

import client from '../graphqlClient'
import { ForgotPassword } from './unauthorized/forgotPassword'
import { Login } from './unauthorized/login'
import { ResetPassword } from './unauthorized/resetPassword'

const Orders = loadable(() => import('pages/orders'), { fallback: <LoadingContent /> })
const Settings = loadable(() => import('pages/settings'), { fallback: <LoadingContent /> })
const Global = loadable(() => import('pages/global'), { fallback: <LoadingContent /> })
const MyProfile = loadable(() => import('pages/myProfile'), { fallback: <LoadingContent /> })

const AuthorizedRouter: React.FC = () => {
  const ability = useAbility()

  return (
    <Switch>
      <Route path='/orders' component={Orders} />
      {ability.can('read', 'Global') && <Route path='/global' component={Global} />}
      {ability.can('read', 'Settings') && <Route path='/settings' component={Settings} />}
      <Route path='/my_profile' component={MyProfile} />
      <Redirect to='/orders' />
    </Switch>
  )
}

const UnauthorizedRouter: React.FC = () => {
  return (
    <Switch>
      <Route path='/reset_password/:token' exact component={ResetPassword} />
      <Route path='/forgot_password' exact component={ForgotPassword} />
      <Route path='/' component={Login} />
    </Switch>
  )
}

const Router: React.FC = () => {
  const { authorizedState, firstLogin } = useCurrentUser()
  const { push } = useHistory()

  useEffect(() => {
    if (authorizedState === AuthorizedState.yes && firstLogin) {
      push({ pathname: '/my_profile', state: { firstLogin: true } })
    }
  }, [authorizedState, firstLogin, push])

  let content
  if (authorizedState === AuthorizedState.yes) {
    content = <AuthorizedRouter />
  } else if (authorizedState === AuthorizedState.no) {
    return <UnauthorizedRouter />
  } else {
    content = <LoadingContent />
  }

  return (
    <Container disableGutters maxWidth={false} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navbar />
      <Container
        disableGutters
        maxWidth={false}
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginBottom: 10 }}
      >
        {content}
      </Container>
    </Container>
  )
}

export const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <NotificationsProvider>
            <CssBaseline />
            <CurrentUserProvider>
              <CurrentRoleProvider>
                <I18nProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BrowserRouter>
                      <Router />
                    </BrowserRouter>
                  </LocalizationProvider>
                </I18nProvider>
              </CurrentRoleProvider>
            </CurrentUserProvider>
          </NotificationsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}
