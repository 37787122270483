import { Button, Stack } from '@mui/material'
import React from 'react'

import { useI18n } from 'hooks'

import { LinkButton } from 'components/link'

export const SaveCancelButtons: React.FC<{ cancelUrl?: string }> = ({ cancelUrl }) => {
  const { t } = useI18n()

  return (
    <Stack sx={{ paddingTop: 3 }} direction='row' spacing={1} justifyContent='flex-end'>
      {cancelUrl && (
        <LinkButton variant='outlined' color='secondary' to={cancelUrl}>
          {t('buttons.cancel')}
        </LinkButton>
      )}
      <Button variant='contained' type='submit'>
        {t('buttons.save')}
      </Button>
    </Stack>
  )
}
