import createSvgIcon from '@mui/material/utils/createSvgIcon'
import React from 'react'

/* eslint-disable max-len */
export default createSvgIcon(
  <svg>
    <rect x='0' y='0' width='24' height='24' rx='2' />,
    <path
      className='HrxSvgIconCheck'
      d='M16.5 9.68041L14.8421 8L11.1 12.3814L9.15789 10.1031L7.5 11.7835L11.1 16L16.5 9.68041Z'
    />
  </svg>,
  'SecondaryCheckedCheckbox'
)
/* eslint-enable max-len */
