import { Button, ButtonProps, ButtonTypeMap, Dialog, DialogActions, DialogContent, Tooltip } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { useI18n, useMountedRef } from 'hooks'

import { IconButton } from './iconButton'

export const ConfirmableButton = <D extends React.ElementType = ButtonTypeMap['defaultComponent'], P = unknown>(
  props: Omit<ButtonProps<D, P>, 'onClick'> & {
    confirmContent: React.ReactNode
    confirmLabel?: string
    onClick: () => Promise<unknown>
    title?: string
    iconButton?: boolean
  }
) => {
  const { confirmContent, confirmLabel, onClick, title, iconButton, ...buttonProps } = props
  const { t } = useI18n()

  const mounted = useMountedRef()

  const [dialogOpen, setDialogOpen] = useState(false)
  const openDialog = useCallback(() => setDialogOpen(true), [])
  const closeDialog = useCallback(() => mounted.current && setDialogOpen(false), [mounted])

  const ButtonComponent: React.ElementType = iconButton ? IconButton : Button

  return (
    <>
      <Tooltip title={title || ''}>
        <ButtonComponent {...buttonProps} onClick={openDialog} />
      </Tooltip>
      {dialogOpen && (
        <Dialog open onClose={closeDialog}>
          <DialogContent>{confirmContent}</DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: 6 }}>
            <Button variant='outlined' color='secondary' onClick={closeDialog} data-action='cancel'>
              {t('buttons.cancel')}
            </Button>
            <Button variant='contained' onClick={() => onClick().then(closeDialog)} data-action='confirm'>
              {confirmLabel || t('buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
