import { DirectUpload, Blob } from '@rails/activestorage'
import type { Order } from 'graphqlSchema'
import { isUndefined, isNull } from 'lodash'

export { DateFormat, formatBaseTime, formatDate, ensureDate } from './date'
export { orderStatusColor } from './statusColors'

export { Can } from 'hooks/useCurrentUser'

export * from './validators'

export function getMetaValue(name: string) {
  const metaTag = document.querySelector(`meta[name='${name}']`)
  return metaTag ? metaTag.getAttribute('content') : null
}

export function getCsrfHeaders() {
  const csrfToken = getMetaValue('csrf-token') || ''

  return {
    'X-CSRF-Token': csrfToken
  }
}

export function formatOrderDimensions(order?: Pick<Order, 'lengthCm' | 'widthCm' | 'heightCm'> | null) {
  return order ? `${order.lengthCm} x ${order.widthCm} x ${order.heightCm}` : ''
}

export const defaultsCustomizer = <T,>(sourceValue: T, defaultValue: T) =>
  isUndefined(sourceValue) || isNull(sourceValue) ? defaultValue : sourceValue

export const uploadFile = (file: File) => {
  const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')

  return new Promise<Blob>((resolve, reject) => {
    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob)
      }
    })
  })
}
