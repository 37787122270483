import { useUpdateCurrentUserLanguageMutation, LocaleEnum } from 'graphqlSchema'
import I18n from 'i18n'
import { Scope } from 'i18n-js'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useCurrentUser } from 'hooks'

type I18nTranslateOptions = Parameters<typeof I18n.t>[1]

const I18nContext = createContext<{ currentLocale: LocaleEnum; setCurrentLocale: (locale: LocaleEnum) => void }>({
  currentLocale: I18n.locale as LocaleEnum,
  setCurrentLocale: () => {}
})

export const PublicI18nProvider: React.FC = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState(I18n.locale as LocaleEnum)

  const value = useMemo(() => ({ currentLocale, setCurrentLocale }), [currentLocale])

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
}

export const I18nProvider: React.FC = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState(I18n.locale as LocaleEnum)
  const { locale: persistedLocale, authorized } = useCurrentUser()
  const [update] = useUpdateCurrentUserLanguageMutation()

  useEffect(() => {
    if (persistedLocale) {
      setCurrentLocale(persistedLocale)
    }
  }, [persistedLocale])

  useEffect(() => {
    if (authorized && currentLocale !== persistedLocale) {
      update({ variables: { input: { locale: currentLocale } } })
    }
  }, [currentLocale, persistedLocale, authorized, update])

  const value = useMemo(() => ({ currentLocale, setCurrentLocale }), [currentLocale])

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
}

export const useI18n = () => {
  const { currentLocale, setCurrentLocale } = useContext(I18nContext)

  const t = useCallback(
    (scope: Scope, options?: I18nTranslateOptions) => {
      return I18n.t(scope, { ...(options || {}), locale: currentLocale })
    },
    [currentLocale]
  )

  return { t, currentLocale, setCurrentLocale }
}

export const Translate: React.FC<{ value: string; scope: string }> = ({ value, scope }) => {
  const { t } = useI18n()
  return <>{t(value, { scope, defaultValue: value })}</>
}
