import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export const DEFAULT_PER_PAGE = 10

const PAGE_PARAM = 'page'

export const usePagination = () => {
  const perPage = DEFAULT_PER_PAGE

  const history = useHistory()

  const { search } = useLocation()

  const setPage = useCallback(
    (newPage: number) => {
      const query = new URLSearchParams(search)
      query.set(PAGE_PARAM, newPage.toString())
      history.replace({ search: query.toString() })
    },
    [history, search]
  )

  return useMemo(() => {
    const query = new URLSearchParams(search)
    const page = parseInt(query.get(PAGE_PARAM) || '', 10) || 0

    const paginationQueryVariables = { first: perPage, after: btoa(`${page * perPage}`) }
    const paginationTableProps = { page, setPage, perPage }

    return { paginationQueryVariables, paginationTableProps }
  }, [search, perPage, setPage])
}
