import { Box } from '@mui/material'
import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { getCsrfHeaders } from 'utils'

import { useI18n, useNotifications } from 'hooks'

import { Link } from 'components/link'
import { CalloutTypography, LoginInput, LoginButton } from 'components/unauthorized/common'
import { Layout } from 'components/unauthorized/layout'

interface Inputs {
  email: string
  password: string
}

export const Login = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting }
  } = useForm<Inputs>({ defaultValues: { email: '', password: '' } })
  const { t } = useI18n()

  const { notifyError } = useNotifications()

  const onSubmit: SubmitHandler<Inputs> = async ({ email, password }) => {
    const { ok } = await fetch(' /users/sign_in', {
      method: 'POST',
      body: JSON.stringify({ user: { email, password } }),
      headers: {
        ...getCsrfHeaders(),
        'Content-Type': 'application/json'
      }
    })

    if (ok) {
      // To refresh CSRF token. TODO: get that from devise response.
      window.location.reload()
    } else {
      notifyError(t('login.login_invalid'))
      setError('email', { type: 'invalid' })
      setError('password', { type: 'invalid' })
    }
  }

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CalloutTypography variant='h6' sx={{ fontWeight: 700 }}>
          {t('login.title')}
        </CalloutTypography>
        <LoginInput
          name='email'
          control={control}
          rules={{ required: true }}
          defaultValue=''
          variant='filled'
          label={t('login.email')}
          fullWidth
          sx={{ mt: 2 }}
        />
        <LoginInput
          name='password'
          control={control}
          rules={{ required: true }}
          defaultValue=''
          variant='filled'
          label={t('login.password')}
          type='password'
          fullWidth
          sx={{ mt: 2 }}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white' }}>
          <LoginButton variant='outlined' color='primary' type='submit' disabled={isSubmitting}>
            {t('login.submit')}
          </LoginButton>
          <Link to='/forgot_password'>{t('login.forgot_password')}</Link>
        </Box>
      </form>
    </Layout>
  )
}
