import { Link as MuiLink, LinkProps as MuiLinkProps, Button, ButtonProps } from '@mui/material'
import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

type RelevantRouterLinkProps = Pick<RouterLinkProps, 'to' | 'replace'>

export interface LinkProps extends MuiLinkProps, RelevantRouterLinkProps {}

// This is how MUI documentation suggests to do it :(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Link = (props: LinkProps) => <MuiLink {...props} component={RouterLink as any} />

export interface LinkButtonProps extends ButtonProps, RelevantRouterLinkProps {}

// This is how MUI documentation suggests to do it :(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LinkButton = (props: LinkButtonProps) => <Button {...props} component={RouterLink as any} />
