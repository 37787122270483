import { AccountIcon } from 'icons'
import React from 'react'
import { Link } from 'react-router-dom'

import { useI18n } from 'hooks'

import { IconButton } from 'components/buttons'

export const ProfileMenu: React.FC = () => {
  const { t } = useI18n()

  return (
    <IconButton variant='text' component={Link} to='/my_profile' title={t('my_profile.title')}>
      <AccountIcon />
    </IconButton>
  )
}
