import { OrderStatusEnum } from 'graphqlSchema'

import { ChipProps } from 'components/chip'

export const orderStatusColor = (status?: OrderStatusEnum) => {
  let colorVariant: ChipProps['colorVariant']
  if (status === OrderStatusEnum.New) {
    colorVariant = 'orange'
  } else if (status === OrderStatusEnum.Ready) {
    colorVariant = 'green'
  } else {
    colorVariant = 'blue'
  }
  return colorVariant
}
