import { ApolloClient, InMemoryCache, ServerError } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { CURRENT_SESSION_ROLE_STORAGE_ID } from 'hooks/useCurrentRole'
import { onUnauthorizedApiResponse } from 'hooks/useCurrentUser'
import { getSessionStorageValue } from 'hooks/useSessionState'

import { getCsrfHeaders } from './utils'

const httpLink = new BatchHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
  headers: getCsrfHeaders()
})

const cache = new InMemoryCache({
  typePolicies: {
    ViewConfiguration: {
      keyFields: ['key']
    },
    MutationError: {
      keyFields: false
    }
  }
})

const addCurrentSessionLink = setContext((_request, _previousContext) => {
  const roleId = getSessionStorageValue(CURRENT_SESSION_ROLE_STORAGE_ID, '')

  return {
    headers: { 'X-Current-Role': roleId }
  }
})

const resetAuthLink = onError(({ networkError }) => {
  if (networkError && networkError.name === 'ServerError' && (networkError as ServerError).statusCode === 401) {
    cache.reset()
    onUnauthorizedApiResponse()
  }
})

const link = addCurrentSessionLink.concat(resetAuthLink).concat(httpLink)

const client = new ApolloClient({ link, cache })

export default client
