import { Button, Typography } from '@mui/material'
import styled from 'styled-components'

import { TextFieldInput } from 'components/inputs'

export const LoginButton = styled(Button)`
  border-color: white;
  color: white;
  &:hover {
    border: 1px solid white;
    background-color: white;
    color: ${(props) => props.theme.palette.primary.main};
  }
`

export const LoginInput = styled(TextFieldInput)`
  .MuiInputLabel-root {
    color: white;
    &.Mui-error {
      color: #ff6161;
    }
  }
  .MuiFilledInput-root {
    border-bottom: 1px solid transparent;
    border-radius: ${(props) => (props.theme.shape.borderRadius as number) * 1.5}px;
    background-color: rgba(0, 0, 0, 0.12);
    color: white;
    &.Mui-error {
      border-bottom: 1px solid white;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.16);
    }
    &:before,
    &:after {
      display: none;
    }
  }
  .MuiFilledInput-input {
    border-radius: inherit;
  }
`

export const CalloutTypography = styled(Typography)`
  color: white;
`
