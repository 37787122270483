import { Button, Menu, PopoverOrigin, ButtonProps } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { CloseDropdownContext } from './common'

type DropdownProps = {
  label: React.ReactNode
  vertical?: PopoverOrigin['vertical']
  horizontal?: PopoverOrigin['horizontal']
} & ButtonProps

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  children,
  vertical = 'bottom',
  horizontal = 'left',
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const origin = { vertical, horizontal }

  return (
    <CloseDropdownContext.Provider value={handleClose}>
      <Button
        sx={{ borderRadius: 1 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color='secondary'
        {...buttonProps}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={origin}
        transformOrigin={{ vertical: 'top', horizontal: horizontal }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </CloseDropdownContext.Provider>
  )
}
