import { Ability } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import { useCurrentUserQuery, CurrentUserQuery } from 'graphqlSchema'
import React, { useEffect, useState, createContext, useContext, useMemo, useRef } from 'react'

const MAX_REFETCHES = 10

export enum AuthorizedState {
  maybe,
  yes,
  no
}

type CurrentUser = Partial<CurrentUserQuery['currentUser']> & {
  authorizedState: AuthorizedState
  authorized: boolean
  resetAuthorizedState: () => void
}

type AbilityRuleButAssumeFieldsCanNotBeNull = Omit<CurrentUserQuery['currentAbilities'][number], 'fields'> & {
  fields?: string[]
}

const RESET_CURRENT_USER_EVENT = 'useCurrentUser.reset'

const CurrentUserContext = createContext<CurrentUser>({
  authorizedState: AuthorizedState.maybe,
  authorized: false,
  resetAuthorizedState: () => {}
})

const detectSubjectType = (subject: { __typename: string }) => subject.__typename

const AbilityContext = createContext(new Ability([], { detectSubjectType }))

export const onUnauthorizedApiResponse = () => {
  window.dispatchEvent(new Event(RESET_CURRENT_USER_EVENT))
}

export const CurrentUserProvider: React.FC = ({ children }) => {
  const [authorizedState, setAuthorizedState] = useState(AuthorizedState.maybe)
  const refetchAttemptsRef = useRef(0)

  const { data, refetch, loading } = useCurrentUserQuery()

  useEffect(() => {
    const resetCurrentUser = () => {
      setAuthorizedState(AuthorizedState.no)
      sessionStorage.clear()
    }

    window.addEventListener(RESET_CURRENT_USER_EVENT, resetCurrentUser)

    return () => window.removeEventListener(RESET_CURRENT_USER_EVENT, resetCurrentUser)
  }, [])

  useEffect(() => {
    if (authorizedState === AuthorizedState.maybe) {
      if (data?.currentUser.id) {
        setAuthorizedState(AuthorizedState.yes)
      } else if (!loading && refetchAttemptsRef.current < MAX_REFETCHES) {
        refetchAttemptsRef.current += 1
        refetch()
      }
    }
  }, [authorizedState, refetch, data?.currentUser.id, loading])

  const currentUser = useMemo<CurrentUser>(
    () => ({
      ...(data?.currentUser || {}),
      authorizedState,
      authorized: authorizedState === AuthorizedState.yes,
      resetAuthorizedState: () => setAuthorizedState(AuthorizedState.maybe)
    }),
    [authorizedState, data]
  )

  const currentAbility = useMemo(() => {
    return new Ability((data?.currentAbilities || []) as AbilityRuleButAssumeFieldsCanNotBeNull[], {
      detectSubjectType
    })
  }, [data])

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <AbilityContext.Provider value={currentAbility}>{children}</AbilityContext.Provider>
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => {
  return useContext(CurrentUserContext)
}

export const useAbility = () => useContext(AbilityContext)

export const Can = createContextualCan(AbilityContext.Consumer)
