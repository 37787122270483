import {
  TextField,
  Autocomplete as MuiAutocomplete,
  AutocompleteInputChangeReason,
  CircularProgress
} from '@mui/material'
import {
  OrderSortEnum,
  SortDirectionEnum,
  useOrdersAutocompleteLazyQuery,
  OrdersAutocompleteQuery,
  FilterGroupingEnum,
  OrdersAutocompleteQueryVariables
} from 'graphqlSchema'
import { LocationIcon, SearchIcon } from 'icons'
import { throttle, compact } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useI18n } from 'hooks'

type AutocompleteOrder = OrdersAutocompleteQuery['orders']['nodes'][number]

const getOptionLabel = (option: AutocompleteOrder) => {
  const { senderReference, partnerTrackingNumber } = option
  return compact([partnerTrackingNumber, senderReference]).join(' / ')
}

const queryOptions = (query: string) => {
  const matchesQuery = query.length > 0 ? `%${query}%` : undefined

  const variables: OrdersAutocompleteQueryVariables = {
    q: {
      sorts: [{ name: OrderSortEnum.CreatedAt, dir: SortDirectionEnum.Desc }],
      senderReferenceMatches: matchesQuery,
      barcodeMatches: matchesQuery,
      returnBarcodeMatches: matchesQuery,
      recipientEmailMatches: matchesQuery,
      recipientNameMatches: matchesQuery,
      m: FilterGroupingEnum.Or
    },
    first: 5
  }

  return { variables }
}

const inputThrottleMs = 500

const Autocomplete = styled(MuiAutocomplete)`
  .MuiFilledInput-root {
    width: 250px;
    color: ${(props) => props.theme.palette.text.secondary};
    border-radius: ${(props) => (props.theme.shape.borderRadius as number) * 1.5}px;
    background-color: ${(props) => props.theme.palette.grey[200]};
    padding: ${(props) => props.theme.spacing(0.5, 1)};
    &:before,
    &:after {
      display: none;
    }
  }
`

export const OrderTrackingMenu: React.FC = () => {
  const [query, setQuery] = useState('')
  const [queryChanged, setQueryChanged] = useState(false)
  const { t } = useI18n()

  const { push } = useHistory()

  const [runQuery, { data, loading }] = useOrdersAutocompleteLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setQueryChanged(false)
  })

  const fetchOptions = useMemo(
    () =>
      throttle(
        (query: string) => {
          runQuery(queryOptions(query))
        },
        inputThrottleMs,
        { leading: false, trailing: true }
      ),
    [runQuery]
  )

  const onInputChange = useCallback(
    (_e, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input') {
        setQueryChanged(true)
        setQuery(value)
        fetchOptions(value)
      } else {
        setQuery('')
      }
    },
    [fetchOptions]
  )

  const onChange = useCallback(
    (_e, value) => {
      value as AutocompleteOrder | null
      if (value) {
        push(`/orders/${value.id}`)
        setQuery('')
      }
    },
    [push]
  )

  const onOpen = useCallback(() => runQuery(queryOptions('')), [runQuery])

  const apparentlyLoading = queryChanged || loading

  const options = apparentlyLoading ? [] : data?.orders.nodes || []

  return (
    <Autocomplete
      inputValue={query}
      onInputChange={onInputChange}
      onOpen={onOpen}
      onChange={onChange}
      autoHighlight
      blurOnSelect
      disableClearable
      forcePopupIcon={false}
      options={options}
      loading={apparentlyLoading}
      getOptionLabel={(option) => getOptionLabel(option as AutocompleteOrder)}
      filterOptions={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <LocationIcon color='primary' />,
            endAdornment: apparentlyLoading ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              <SearchIcon sx={{ color: 'text.primary' }} />
            )
          }}
          variant='filled'
          placeholder={t('navbar.track_order')}
        />
      )}
    />
  )
}
