import { Drawer as MuiDrawer, DrawerProps, Divider, Box, Stack } from '@mui/material'
import { MenuIcon } from 'icons'
import React from 'react'
import styled from 'styled-components'

import { useCurrentUser } from 'hooks'

import { IconButton } from 'components/buttons'
import { Footer } from 'components/footer'

import { AuthMenu } from './authMenu'
import { LanguageMenu } from './languageMenu'
import { NavigationItems } from './navigationItems'
import { ProfileMenu } from './profileMenu'

const Drawer = styled(MuiDrawer)`
  > .MuiPaper-root {
    max-width: 80%;
    width: 450px;
  }
`

export interface MobileNavigationProps {
  open: boolean
  onToggle: (state: boolean) => void
}

export const MobileNavigation: React.FC<MobileNavigationProps & DrawerProps> = ({ onToggle, ...props }) => {
  const { authorized } = useCurrentUser()

  return (
    <Drawer
      anchor='right'
      onClose={() => onToggle(false)}
      variant='temporary'
      ModalProps={{
        keepMounted: true
      }}
      sx={{
        display: { xs: 'block', md: 'none' }
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Stack
          direction='row'
          divider={<Divider sx={{ height: 28 }} orientation='vertical' />}
          spacing={3}
          sx={{ alignItems: 'center', justifyContent: 'flex-end', py: 1.5, px: 2.5 }}
        >
          <span></span>
          <LanguageMenu />
          {authorized && <ProfileMenu />}
          {authorized && <AuthMenu />}
          <IconButton variant='text' onClick={() => onToggle(false)}>
            <MenuIcon color='primary' fontSize='large' />
          </IconButton>
        </Stack>
        <Box flexGrow={1} py={5} sx={{ overflowY: 'auto' }}>
          <NavigationItems vertical />
        </Box>
        <Footer />
      </Box>
    </Drawer>
  )
}
