import { FormControl, FormHelperText, Input, InputLabel, TextFieldProps } from '@mui/material'
import { uniqueId } from 'lodash'
import React, { useMemo } from 'react'
import { useFormState } from 'react-hook-form'
import { UseFormRegister } from 'react-hook-form'

import { useFieldErrorHelper } from 'hooks'

import { UniversalControllerProps } from './common'

export const FileInput = ({
  name,
  control,
  fullWidth = true,
  register,
  label,
  disabled
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UniversalControllerProps & { register: UseFormRegister<any> } & TextFieldProps) => {
  const fieldErrorHelper = useFieldErrorHelper()

  const fieldId = useMemo(() => uniqueId('FileInput'), [])
  const { errors } = useFormState({ name, control })
  const error = errors[name]
  const invalid = !!error

  return (
    <FormControl variant='standard' error={invalid} fullWidth={fullWidth} disabled={disabled}>
      <InputLabel htmlFor={fieldId}>{label}</InputLabel>
      <Input id={fieldId} type='file' {...register(name)} disabled={disabled} />
      <FormHelperText>{fieldErrorHelper(error)}</FormHelperText>
    </FormControl>
  )
}
