import { usePickupLocationsListQuery, SortDirectionEnum, PickupLocationSortEnum } from 'graphqlSchema'
import { map } from 'lodash'
import { useMemo } from 'react'

export const useCustomerPickupLocationOptions = (customerIdEq: string) => {
  const { data } = usePickupLocationsListQuery({
    variables: {
      q: { sorts: [{ name: PickupLocationSortEnum.Name, dir: SortDirectionEnum.Asc }], customerIdEq }
    },
    fetchPolicy: 'cache-and-network'
  })

  return useMemo(() => {
    return map(data?.pickupLocations?.nodes || [], ({ id, name }) => ({ id: id, label: name }))
  }, [data?.pickupLocations])
}
