import createSvgIcon from '@mui/material/utils/createSvgIcon'
import React from 'react'

/* eslint-disable max-len */
export default createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM2 1H22C22.5523 1 23 1.44772 23 2V22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22V2C1 1.44772 1.44772 1 2 1Z'
  />,
  'Checkbox'
)
/* eslint-enable max-len */
