import { MenuItem } from '@mui/material'
import { LocaleEnum } from 'graphqlSchema'
import { map } from 'lodash'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { useI18n } from 'hooks'

import { Dropdown, CloseDropdownContext } from 'components/dropdowns'

const StyledDropdown = styled(Dropdown)`
  &.MuiButtonBase-root {
    min-width: 48px;
    text-transform: uppercase;
    padding: ${(props) => props.theme.spacing(1, 2)};
  }
`

type LanguageItemProps = {
  locale: LocaleEnum
  currentLocale: LocaleEnum
  setCurrentLocale: (locale: LocaleEnum) => void
}

const LanguageItem: React.FC<LanguageItemProps> = ({ locale, currentLocale, setCurrentLocale }) => {
  const handleClose = useContext(CloseDropdownContext)

  const onClick = () => {
    setCurrentLocale(locale)
    handleClose()
  }

  return (
    <MenuItem
      sx={{ textTransform: 'uppercase' }}
      onClick={onClick}
      data-action={`switch-locale-${locale}`}
      selected={locale === currentLocale}
    >
      {locale}
    </MenuItem>
  )
}

export const LanguageMenu: React.FC<{ className?: string }> = ({ className }) => {
  const { currentLocale, setCurrentLocale } = useI18n()

  const menuItems = map(LocaleEnum, (locale) => (
    <LanguageItem locale={locale} currentLocale={currentLocale} setCurrentLocale={setCurrentLocale} key={locale} />
  ))

  return (
    <StyledDropdown
      label={currentLocale}
      size='large'
      color='secondary'
      data-action='switch-locale'
      horizontal='center'
      className={className}
    >
      {menuItems}
    </StyledDropdown>
  )
}
