import { useCourierCustomerDeliveryCountriesQuery, DeliveryKindEnum } from 'graphqlSchema'
import { map } from 'lodash'
import { useMemo } from 'react'

import { useI18n } from './useI18n'

export const useCourierCustomerDeliveryCountryOptions = (customerIdEq: string) => {
  const { t } = useI18n()

  const { data } = useCourierCustomerDeliveryCountriesQuery({
    variables: {
      q: {
        customerIdEq,
        customerDeliveryCountryPriceRulesFixedPriceExVatNotNull: true,
        customerDeliveryCountryPriceRulesDeliveryKindEq: DeliveryKindEnum.Courier
      }
    }
  })

  return useMemo(() => {
    return map(data?.courierDeliveryLocationCountries || [], (country) => ({
      id: country,
      label: t(`countries.${country}`)
    }))
  }, [data?.courierDeliveryLocationCountries, t])
}
