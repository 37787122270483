import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import parseJSON from 'date-fns/parseJSON'

export enum DateFormat {
  UI = 'dd.MM.yy HH:mm',
  UI_LONG = 'dd.MM.yyyy HH:mm',
  UI_SECONDS = 'dd.MM.yyyy HH:mm:ss',
  TIME = 'HH:mm',
  TIME_SECONDS = 'HH:mm:ss',
  DATE = 'dd.MM.yy'
}

export const ensureDate = (maybeDate: string | Date): Date => {
  let date!: Date

  if (maybeDate.constructor === Date) {
    date = maybeDate
  } else if (maybeDate.constructor === String) {
    date = maybeDate.includes('Z') ? parseJSON(maybeDate) : parseISO(maybeDate)
  }

  return date
}

export const formatDate = (maybeDate: string | Date, outputFormat?: 'iso' | DateFormat | string): string => {
  try {
    const date = ensureDate(maybeDate)

    if (outputFormat === 'iso') {
      return formatISO(date)
    } else {
      return format(date, outputFormat || DateFormat.UI)
    }
  } catch (error) {
    return '-'
  }
}

export const formatBaseTime = (maybeDate: string | Date) => {
  try {
    const date = ensureDate(maybeDate)
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const newDate = new Date('01.01.2000')
    newDate.setHours(hours, minutes, 0, 0)

    const isoDate = newDate.toISOString()

    return isoDate
  } catch (error) {
    return ''
  }
}
