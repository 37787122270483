import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { getCsrfHeaders } from 'utils'
import * as Yup from 'yup'

import { useI18n, useNotifications } from 'hooks'

import { CalloutTypography, LoginInput, LoginButton } from 'components/unauthorized/common'
import { Layout } from 'components/unauthorized/layout'

interface Inputs {
  password: string
  passwordConfirmation: string
}

const passwordMinLength = 10

const schema = Yup.object().shape({
  password: Yup.string().min(passwordMinLength).required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'errors.reset_password.confirmation_must_match')
    .required()
})

export const ResetPassword = () => {
  const { token } = useParams<{ token: string }>()

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting }
  } = useForm<Inputs>({
    defaultValues: { password: '', passwordConfirmation: '' },
    resolver: yupResolver(schema)
  })
  const { t } = useI18n()
  const { notifyError } = useNotifications()

  const onSubmit: SubmitHandler<Inputs> = async ({ password, passwordConfirmation }) => {
    const { ok } = await fetch(' /users/password', {
      method: 'PUT',
      body: JSON.stringify({
        user: { password, password_confirmation: passwordConfirmation, reset_password_token: token }
      }),
      headers: {
        ...getCsrfHeaders(),
        'Content-Type': 'application/json'
      }
    })

    if (ok) {
      window.location.replace('/')
    } else {
      notifyError(t('login.login_invalid'))
      setError('password', { type: 'invalid' })
      setError('passwordConfirmation', { type: 'invalid' })
    }
  }

  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CalloutTypography variant='h6' sx={{ fontWeight: 700 }}>
          {t('login.reset_password', { count: passwordMinLength })}
        </CalloutTypography>
        <LoginInput
          name='password'
          control={control}
          defaultValue=''
          variant='filled'
          label={t('login.password')}
          type='password'
          fullWidth
          sx={{ mt: 2 }}
        />
        <LoginInput
          name='passwordConfirmation'
          control={control}
          defaultValue=''
          variant='filled'
          label={t('login.password_confirmation')}
          type='password'
          fullWidth
          sx={{ mt: 2 }}
        />
        <Stack sx={{ mt: 2 }} direction='row'>
          <LoginButton variant='outlined' color='primary' type='submit' disabled={isSubmitting}>
            {t('buttons.save')}
          </LoginButton>
        </Stack>
      </form>
    </Layout>
  )
}
