import { Customer, useDeliveryCountryOptionsQuery } from 'graphqlSchema'
import { map } from 'lodash'
import { useMemo } from 'react'

import { useI18n } from './useI18n'

export const useDeliveryCountryOptions = (customerId: Customer['id']) => {
  const { t } = useI18n()

  const { data } = useDeliveryCountryOptionsQuery({ variables: { customerId }, fetchPolicy: 'cache-and-network' })

  return useMemo(() => {
    return map(data?.deliveryLocationCountries, (code) => ({ id: code, label: t(`countries.${code}`) }))
  }, [data?.deliveryLocationCountries, t])
}
