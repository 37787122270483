import {
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps
} from '@mui/material'
import { CheckboxIcon, CheckedCheckboxIcon, SecondaryCheckboxIcon, SecondaryCheckedCheckboxIcon } from 'icons'
import React, { forwardRef } from 'react'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

import { UniversalControllerProps } from './common'

type CheckboxProps = {
  checkboxVariant?: 'filled' | 'outlined'
  valueSelected?: boolean
} & MuiCheckboxProps

const FormControlLabel = styled(MuiFormControlLabel)<{ $valueSelected: CheckboxProps['valueSelected'] }>`
  width: 100%;
  & .MuiFormControlLabel-label {
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    width: 100%;
    ${({ $valueSelected, theme }) =>
      $valueSelected &&
      `
      color: ${theme.palette.grey[900]};
    `}
  }
`

const SecondaryControlLabel = styled(FormControlLabel)<{ $valueSelected: CheckboxProps['valueSelected'] }>`
  & .MuiFormControlLabel-label {
    ${({ $valueSelected, theme }) =>
      $valueSelected &&
      `
      font-weight: ${theme.typography.fontWeightRegular};
    `}
  }
`

const SecondaryCheckbox = styled(Checkbox)`
  color: ${(props) => props.theme.palette.grey[200]};
  &:hover,
  &.Mui-checked {
    color: ${(props) => props.theme.palette.grey[200]};
  }
`

export const StyledCheckbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ checkboxVariant = 'outlined', ...props }, ref) => {
    if (checkboxVariant === 'filled') {
      return (
        <SecondaryCheckbox
          icon={<SecondaryCheckboxIcon />}
          checkedIcon={<SecondaryCheckedCheckboxIcon />}
          {...props}
          ref={ref}
        />
      )
    } else {
      return <Checkbox icon={<CheckboxIcon />} checkedIcon={<CheckedCheckboxIcon />} {...props} ref={ref} />
    }
  }
)
StyledCheckbox.displayName = 'StyledCheckbox'

export const CheckboxInput: React.FC<
  UniversalControllerProps & Pick<FormControlLabelProps, 'label'> & CheckboxProps
> = ({ name, control, rules, defaultValue, label, sx, checkboxVariant = 'outlined', ...checkboxProps }) => {
  const { field } = useController({ name, control, rules, defaultValue })

  const Wrapper = checkboxVariant === 'filled' ? SecondaryControlLabel : FormControlLabel

  return (
    <Wrapper
      sx={sx}
      $valueSelected={field.value}
      control={<StyledCheckbox {...field} checked={field.value} checkboxVariant={checkboxVariant} {...checkboxProps} />}
      label={label}
    />
  )
}
