import { CircularProgress, Container } from '@mui/material'
import React from 'react'

export const LoadingContent: React.FC = () => {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
      <CircularProgress />
    </Container>
  )
}
