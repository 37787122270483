import { createTheme, ThemeOptions } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

type CommonColor = {
  background: string
  accentText: string
  accentIcon: string
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    orange: CommonColor
    blue: CommonColor
    green: CommonColor
  }
}

const baseTheme = {
  palette: {
    grey: {
      50: '#FFF',
      100: '#F9F9FB',
      200: '#EEF0F4',
      300: '#E0E7ED',
      400: '#CECDD2',
      500: '#AAA8B3',
      700: '#6B6B6B',
      900: '#353739'
    },
    text: {
      primary: '#A7A6AB',
      secondary: '#747474'
    },
    primary: {
      main: '#D43636',
      dark: '#B72227'
    },
    common: {
      orange: {
        background: '#fff1e8',
        accentText: '#e37749',
        accentIcon: '#e5916d'
      },
      blue: {
        background: '#edf3fa',
        accentText: '#5289ea',
        accentIcon: '#5289ea'
      },
      green: {
        background: '#e7f9f1',
        accentText: '#85b695',
        accentIcon: '#6c9b8a'
      }
    }
  }
}

const customTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minHeight: '100vh'
        },
        body: {
          minHeight: '100vh',
          overflowX: 'hidden',
          '& div#root': {
            minHeight: '100vh'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 4px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            backgroundColor: baseTheme.palette.grey[200],
            padding: '10px 34px',
            minHeight: 43,
            color: baseTheme.palette.text.primary,
            fontWeight: 600,
            '&:hover': {
              color: baseTheme.palette.grey[900]
            },
            '&.Mui-selected': {
              backgroundColor: baseTheme.palette.primary.main,
              color: 'white'
            }
          }
        },
        indicator: {
          display: 'none'
        }
      }
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          paddingTop: '8px'
        }
      }
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.grey[200],
          boxShadow: 'none',
          margin: '8px 0',
          '.MuiSvgIcon-root': {
            color: baseTheme.palette.grey[500],
            height: '1.25em',
            width: '1.25em'
          }
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: 1,
          backgroundColor: baseTheme.palette.grey[300]
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          color: baseTheme.palette.grey[900]
        },
        listbox: {
          '& .MuiAutocomplete-option': {
            '&.Mui-focused, &[aria-selected="true"]': {
              backgroundColor: baseTheme.palette.grey[100],
              color: baseTheme.palette.grey[900]
            },
            '&[aria-selected="true"].Mui-focused': {
              backgroundColor: 'rgba(164, 164, 191, 0.12)'
            }
          }
        },
        option: {
          margin: 6,
          borderRadius: 5,
          color: baseTheme.palette.text.primary,
          padding: '4px 8px'
        },
        popper: {
          top: '4px !important'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.grey[900]
        },
        input: {
          paddingBottom: '8px'
        },
        underline: {
          '&:before': {
            borderBottom: `1px solid ${baseTheme.palette.grey[300]}`
          },
          '&:hover:not(.Mui-disabled):before, &:after': {
            borderBottom: `1px solid ${baseTheme.palette.grey[500]}`
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.primary,
          fontSize: '0.8125rem',
          '&.Mui-focused': {
            color: 'inherit'
          }
        },
        standard: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0, 3px) scale(0.85)'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: baseTheme.palette.text.primary,
          '&.MuiTypography-inherit': {
            color: 'inherit'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: baseTheme.palette.grey[300]
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          padding: '6px 22px',
          fontWeight: 600,
          '&.MuiButton-contained': {
            border: '1px solid transparent'
          },
          '&.MuiButton-outlinedSecondary': {
            border: `1px solid ${baseTheme.palette.grey[300]}`,
            '&:hover': {
              backgroundColor: baseTheme.palette.grey[300]
            }
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.text.primary
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: 6,
          borderRadius: 5,
          color: baseTheme.palette.text.primary,
          padding: 0,
          '&.MuiMenuItem-gutters': {
            padding: '4px 8px'
          },
          '&:not(.MuiMenuItem-gutters)': {
            '> *': {
              padding: '4px 8px'
            }
          },
          '> .MuiFormControlLabel-root': {
            marginRight: 0,
            marginLeft: 0
          },
          '&:hover, &.Mui-selected': {
            backgroundColor: baseTheme.palette.grey[100],
            color: baseTheme.palette.grey[900],
            '& .MuiCheckbox-root': {
              color: baseTheme.palette.text.primary
            }
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(164, 164, 191, 0.12)'
          },
          '& .MuiTypography-root': {
            fontWeight: 500
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '2px 8px',
          color: baseTheme.palette.grey[400],
          '& svg': {
            fill: 'currentColor'
          },
          '&:hover, &.Mui-checked': {
            color: baseTheme.palette.text.primary
          },
          '&:hover': {
            backgroundColor: 'transparent'
          },
          '& .HrxSvgIconCheck': {
            color: baseTheme.palette.grey[900]
          },
          '&.Mui-disabled .HrxSvgIconCheck': {
            color: baseTheme.palette.grey[400]
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        },
        icon: {
          color: baseTheme.palette.grey[400],
          padding: 8
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(31, 34, 42, 0.2)'
        },
        invisible: {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  palette: {
    secondary: {
      main: baseTheme.palette.grey[700]
    },
    background: {
      default: baseTheme.palette.grey[100]
    }
  },
  typography: {
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13,
    body1: {
      fontSize: '0.8125rem'
    },
    button: {
      textTransform: 'none'
    }
  },
  shadows: [
    'none',
    ...Array(25)
      .fill(null)
      .map((_, i) => `0px 2px ${10 + Math.floor(0.2 * i)}px ${Math.floor(i * 0.25)}px rgba(0,0,0,0.05)`)
  ] as ThemeOptions['shadows']
}

export const theme = createTheme(deepmerge(baseTheme, customTheme))

export type Theme = typeof theme

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
