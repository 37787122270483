import { Link, Tooltip } from '@mui/material'
import { LogoutIcon } from 'icons'
import React from 'react'

import { useI18n } from 'hooks'

import { IconButton } from 'components/buttons'

const AuthMenu: React.FC = () => {
  const { t } = useI18n()

  return (
    <IconButton component={Link} href='/users/sign_out' data-testid='logout'>
      <Tooltip title={t('navbar.logout')}>
        <LogoutIcon fontSize='small' color='secondary' />
      </Tooltip>
    </IconButton>
  )
}

export { AuthMenu }
