import * as Sentry from '@sentry/react'
import React from 'react'
import { getMetaValue } from 'utils'

const dsn = getMetaValue('sentry-dsn')
const environment = getMetaValue('environment')

if (dsn && dsn.length > 0 && environment) {
  Sentry.init({ dsn, environment })
}

const defaultFallback = <p>An error has occurred. Please, reload the page.</p>

export const ErrorBoundary: React.FC<React.ComponentProps<typeof Sentry.ErrorBoundary>> = ({
  children,
  ...sentryProps
}) => {
  return (
    <Sentry.ErrorBoundary fallback={defaultFallback} {...sentryProps}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export { Sentry }
